<template>
  <div class="list-wrapper">
    <!--   筛选区  -->
    <div class="searchClass">
      <a-space class="mb-16" :gutter="16">
        <a-select
          v-model:value="timeType"
          placeholder="类型"
          @change="handleSearchTimeTask"
          style="min-width: 120px"
          class="selClass"
          :allowClear="true"
        >
          <a-select-option key="全部" :value="null">全部</a-select-option>
          <a-select-option key="逾期" :value="1">逾期待办</a-select-option>
        </a-select>
        <a-select
          v-model:value="searchType"
          placeholder="类型"
          @change="handleSearchTask"
          style="min-width: 120px"
          class="selClass"
          :allowClear="true"
        >
          <a-select-option
            v-for="item in items"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>

        <a-range-picker
          allow-clear
          @change="handleChangeByDate"
          style="width: 214px"
        >
          <template #suffixIcon><calendar-outlined /></template>
        </a-range-picker>

        <a-input-search
          placeholder="搜索待办"
          style="width: 186px"
          allow-clear
          @search="value => handleSearch({ search: value || null })"
        />
      </a-space>
    </div>

    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="dataSource"
        :pagination="pagination"
      >
        <template #renderItem="{ item }">
          <a-list-item
            :class="{
              lateBac: item.showLate,
              showBac: item.isShow,
            }"
          >
            <a-list-item-meta>
              <template #title>
                <CaretDownOutlined
                  @click="enter(item)"
                  style="font-size: 12px; margin-right: 10px"
                  v-if="item.subItemsList && item.subItemsList?.length > 0"
                />
                <!-- <img
                  v-else-if="!item.isShow"
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot.png"
                  @click="enter(item)"
                />
                <img
                  v-else
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot-active.png"
                  @click="enter(item)"
                /> -->
                <div class="listName" @click="handleName(item)">
                  {{ item.content }}
                </div>
              </template>
              <!--              <template #avatar> </template>-->
              <template #description>
                <!--          todo 头像放置处     -->
                <div class="description">
                  <div class="pink">
                    <!--  脉圈样式 点击这里才是跳转到脉圈详情页面 -->
                    <div
                      class="avatarClass"
                      v-if="item.personnelType === 1 && item.connections"
                    >
                      <component
                        :is="icons[item?.connections?.icon || 'UserOutlined' ]"
                        class="iconClass"
                        @click="
                          handleGoToContact(
                            item.connections,
                            item.personnelType
                          )
                        "
                      >
                      </component>
                      <div
                        class="textMlC mr-8"
                        @click="
                          handleGoToContact(
                            item.connections,
                            item.personnelType
                          )
                        "
                      >
                        {{ item?.connections?.connectionsName }}
                      </div>
                    </div>

                    <div v-if="item.personnelType === 1" class="flexCalss">
                      <div
                        class="avatarClass"
                        v-for="i in item?.personnelVoList.slice(0, 5)"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText headerTextC"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMlC"
                          @click="handleGoToContact(i)"
                          v-if="item?.personnelVoList.length === 1"
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>

                    <div v-else class="flexCalss">
                      <div
                        class="avatarClass"
                        v-for="i in item?.personnelVoList.slice(0, 12)"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(i)"
                          v-if="item?.personnelVoList.length === 1"
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--          操作按钮放置处    -->
                  <div class="actions">
                    <div class="statisticMun">
                      <div class="item-title">
                        <img
                          src="@/assets/images/reminder/fenbu.png"
                          alt=""
                          class="fenbuImg"
                        />
                        {{
                          item.subCompletedCount ? item.subCompletedCount : 0
                        }}/{{
                          item.subItemsList?.length
                            ? item.subItemsList?.length
                            : 0
                        }}
                      </div>
                    </div>
                    <!--  纯日期时间展示，鼠标移出后隐藏  -->
                    <div class="statistic-item">
                      <div class="item-title">
                        {{ handleReminderTime(item) }}
                      </div>
                    </div>
                    <!-- v-if="item.isShow" -->
                    <div class="ribbon">
                      <a-tooltip placement="top">
                        <template #title>
                          <span>编辑待办</span>
                        </template>
                        <div class="dropDown" @click="handleEdit(item)">
                          <!--<img-->
                          <!--  src="@/assets/images/box/export.png"-->
                          <!--  alt=""-->
                          <!--  class="export-img"-->
                          <!--/>-->
                          <img
                            src="@/assets/images/reminder/edit.png"
                            alt=""
                            class="editImg"
                          />
                        </div>
                      </a-tooltip>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>删除待办</span>
                        </template>
                        <div class="timeTime" @click="handleDelete(item)">
                          <img
                            src="@/assets/images/reminder/del.png"
                            alt=""
                            class="deleteImg"
                          />
                        </div>
                      </a-tooltip>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>全部完成</span>
                        </template>
                        <div class="gouGou" @click="handleGou(item)">
                          <CheckOutlined class="gou" />
                        </div>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>

          <!--       嵌套数据渲染    -->
          <div
            v-if="item.isShow"
            :class="{
              lateBac: item.showLate,
              showBac: item.isShow,
            }"
          >
            <div
              class="listItem"
              v-for="(listItem, index) in item.subItemsList"
              :key="listItem.id"
              :class="{ showBor: item.subItemsList.length - 1 === index }"
            >
              <!--         class="ml-20 mr-13"    -->
              <!-- <div class="ml-10">
                <a-checkbox
                  v-model:value="listItem.id"
                  v-model:checked="listItem.completed"
                  @change="handleCheck(item, listItem)"
                />
              </div> -->

              <div
                class="listName listItemName"
                :class="{ showLine: listItem.completed }"
              >
                {{ listItem.subContent }}
              </div>
              <!--           头像、描述、操作放置处     -->
              <div class="description">
                <!--          操作按钮放置处    -->
                <div class="actions">
                  <!--  纯日期时间展示，鼠标移出后隐藏  -->
                  <div class="statistic-item">
                    <div class="item-title">
                      {{ handleReminderTime(listItem) }}
                    </div>
                  </div>
                  <!-- v-if="item.isShow" -->
                  <div class="ribbon">
                    <a-tooltip placement="top" v-if="listItem.completed">
                      <template #title>
                        <span>撤回完成</span>
                      </template>
                      <div
                        class="dropDown"
                        @click="handleCheck(item, listItem, false)"
                      >
                        <RedoOutlined class="editImg" />
                      </div>
                    </a-tooltip>
                    <a-tooltip placement="top" v-else>
                      <template #title>
                        <span>完成待办</span>
                      </template>
                      <div
                        class="gouGou"
                        @click="handleCheck(item, listItem, true)"
                      >
                        <CheckOutlined class="gou" />
                      </div>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </a-list>
    </a-checkbox-group>
  </div>

  <edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />
  <todo-details
    v-model:visible="opneTodoDetails"
    :init="todoDetailsItem"
    @fnOk="handleTodoDetails"
  />
  <!--  人脉圈待办弹窗   -->
  <cr-edit-page
    v-model:visible="editCrtPage"
    :init="activeItemCr"
    @fnOk="handleEditPageCr"
  />

  <!--  人脉圈待办详情弹窗   -->
  <cr-todo-details
    v-model:visible="opneCrTodoDetails"
    :init="todoDetailsItemCr"
    @fnOk="handleTodoDetailsCr"
  />
</template>

<script>
// Form, DatePicker, Avatar,
import {
  computed,
  createVNode,
  onBeforeUnmount,
  // getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
  getCurrentInstance,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  Checkbox,
  List,
  Modal,
  message,
  DatePicker,
  Select,
  Space,
  Avatar,
  Tooltip,
} from 'ant-design-vue';
import {
  CheckOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  RedoOutlined,
} from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import { local } from '@/utils/storage';
import { setAvatar } from '@/utils';
import * as icons from '@ant-design/icons-vue';
import editPage from '@/views/today/homePage/components/editPage';
import todoDetails from '@/views/today/homePage/components/todoDetails';
// 人脉圈编辑和详情
import crEditPage from '@/views/contactsCircle/detailsPage/components/crEditPage';
import crTodoDetails from '@/views/contactsCircle/detailsPage/components/crTodoDetails';
import contactsCircleApi from '@/api/contactsCircle';
import moment from 'moment/moment';

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    // ASpace: Space,
    ACheckbox: Checkbox,
    // AAvatar: Avatar,
    CheckOutlined,
    editPage,
    todoDetails,
    // AForm: Form,
    // AFormItem: Form.Item,
    // ADatePicker: DatePicker,
    ARangePicker: DatePicker.RangePicker,
    CalendarOutlined,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    crEditPage,
    crTodoDetails,
    AAvatar: Avatar,
    // Operation,
    CaretDownOutlined,
    ATooltip: Tooltip,
    RedoOutlined,
  },
  props: {
    type: String,
    search: Object,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      editCrtPage: false,
      activeItemCr: {},
      opneCrTodoDetails: false,
      todoDetailsItemCr: {},
      loading: false,
      showEditDelete: false,
      editPage: false,
      activeItem: {},
      opneTodoDetails: false,
      todoDetailsItem: {},
      dataSource: [],
      searchDate: {
        startTime: null,
        endTime: null,
      },
      selectedKeys: [],
      current: 1,
      isCurrent: '',
      size: 10,
      total: 0,
      showTime: false,
      content: '',
      searchType: null,
      timeType: null,
    });
    const form = reactive({
      time: '',
    });

    const computedState = () => {
      if (state.dataSource.length > 0) {
        return {
          current: state.current,
          pageSize: state.size,
          total: state.total,
          showLessItems: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => {
            return `共 ${total} 条，当前显示 ${range[0]}-${range[1]} 条`;
          },
          onChange: (current, pageSize) => initList(current, pageSize),
          onShowSizeChange: (current, pageSize) => initList(current, pageSize),
        };
      }
    };

    const pagination = computed(() => {
      return computedState();
    });

    const initList = (current, size) => {
      state.loading = true;
      state.current = current;
      state.size = size;
      // recentlyTodoPage contactTodoMatterPage
      todayApi
        .contactTodoMatterPage(route.path, {
          userId: store.state.account.user.id,
          contactId: route.params.id,
          completed: false,
          current,
          size,
          ...props.search,
          ...state.searchDate,
          content: state.content,
          personnelType: state.searchType,
          type: state.timeType,
          currentTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        })
        .then(({ records, total, current }) => {
          // console.log('records待办', records, current, typeof current);
          state.dataSource = records;
          // 新增一个变量来改变鼠标移入状态
          state.dataSource.map(item => {
            item.isShow = false;
            // 将字符串转换为日期对象
            let strDate = new Date(item.reminderTime);
            // 获取当前时间
            let currentDate = new Date();
            // 比较两个日期对象
            if (strDate < currentDate) {
              item.showLate = true;
            } else {
              item.showLate = false;
            }
          });
          state.total = parseInt(total);
          // 拿到的分页需要存起来 current: "1"
          state.isCurrent = parseInt(current);

          state.selectedKeys = [];
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const goTo = ({ id, eventId, campaignId }) => {
      // 存储  campaignId
      local.set('CAMPAIGNID', campaignId);
      router.push(`${route.path}/${id}/${eventId}/${campaignId}`);
    };

    watch([props.search, () => props.type], () => initList(1, state.size));
    initList(state.current, state.size);

    // 分页处理
    const refreshGrid = delLength => {
      // 列表删除掉的个数 delLength
      let newTotal = state.total - delLength;
      // newTotalToStr 字符串后面的数字是0， 表示这页删除完，回到第一页去
      let newTotalToStr = newTotal
        .toString()
        .charAt(newTotal.toString().length - 1);

      initList(newTotalToStr === '0' ? 1 : state.isCurrent, state.size);
    };

    const setId = record => {
      // console.log('record', record);
      // activityApi
      //     .getCampaignById('', { campaignId: record.campaignId })
      //     .then(res => {
      //       store.commit('common/setCampaignOrgId', res.orgId);
      //       store.commit('common/setCreateUser', res.createUser);
      //
      //       activityApi
      //           .delete(`${props.type}:delete`, { ids: [record.id] })
      //           .then(() => {
      //             proxy.$message.success('操作成功');
      //             initList(1, state.size);
      //           });
      //     });
    };

    // };
    // // 全局创建一个点击事件:bodyCloseMenus
    onMounted(() => {
      // document.addEventListener('click', colseSift);
      proxy.$bus.on('getAddData', value => {
        console.log('getAddData', value);
        if (value) {
          initList(1, state.size);
        }
      });

      proxy.$bus.on('sxOnePage', value => {
        if (value) {
          initList(1, state.size);
        }
      });
    });
    // // 在页面注销前，将点击事件给移除
    onBeforeUnmount(() => {});

    const rules = {
      time: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
    };

    return {
      options: [
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: () => `${props.type}:detail`,
          fnClick: goTo,
        },
        {
          type: 'delete',
          permission: () => `${props.type}:delete`,
          show: record => {
            let orgIds = store.state.common.orgIds?.includes(record.orgId);
            if (orgIds || store.state.common.userId === record.createUser) {
              return true;
            } else {
              return false;
            }
          },
          // disabled: record => {
          //   let orgIds = store.state.common.orgIds?.includes(record.orgId);
          //   if (orgIds || store.state.common.userId === record.createUser) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // },
          fnClick: record => {
            setId(record);
          },
        },
      ],
      pagination,
      ...toRefs(state),
      setAvatar,
      icons,
      rules,
      form,
      // 0个人1脉圈2逾期， 不传就是全部
      items: [
        { value: 1, label: '人脉圈待办' },
        { value: 0, label: '联系人待办' },
        // { value: 2, label: '逾期待办' },
        { value: '', label: '全部' },
      ],
      handleName(item) {
        console.log('handleName', item);
        if (item.personnelType === 1) {
          state.opneCrTodoDetails = true;
          Object.assign(state.todoDetailsItemCr, item);
        } else {
          Object.assign(state.todoDetailsItem, item);
          // 跟联系人详情页面同一个弹窗
          state.opneTodoDetails = true;
        }
      },

      handleExport(item) {
        state.dataSource.map(i => {
          if (i.id === item.id) {
            state.showEditDelete = !state.showEditDelete;
            // i.showEditEelBut = !i.showEditEelBut;
          }
        });
        // console.log('records66', state.dataSource);
      },
      handleGoToContact(record, type) {
        // router.push(`/contacts/${record.id}`);
        if (type === 1) {
          // 点击脉圈跳转去脉圈详情
          router.push(`/contactsCircle/${record.parentId}/${record.id}`);
        } else {
          router.push(`/contacts/${record.id}`);
        }
      },

      handleChange: selectedKeys => {
        if (selectedKeys.length) {
          if (selectedKeys.length === state.dataSource.length) {
            state.checkAll = true;
          } else {
            state.checkAll = false;
          }
        } else {
          state.checkAll = false;
        }
      },

      getStatus: item => {
        const now = new Date().getTime();
        const startTime = new Date(item.startTime).getTime();
        const endTime = new Date(item.endTime).getTime();
        if (now < startTime) return 0;
        if (now > endTime) return 1;
      },

      enter: item => {
        // console.log('触发了 onmouseenter 鼠标移入事件！');
        // state.dataSource

        state.dataSource.map(i => {
          if (i.id === item.id) {
            // i.isShow = true;
            i.isShow = !i.isShow;
          }
        });
      },

      leave: item => {
        // console.log('触发了mouseleave 鼠标移出事件！', item);

        state.dataSource.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
            // 鼠标移出后关闭下拉
            // state.showEditDelete = false
          }
        });
      },

      handleEdit(item) {
        // console.log('handleEdit', item);
        if (item.personnelType === 1) {
          // 如果是脉圈，则调取查询接口，如果返回true，则打开脉圈编辑，否则打开联系人编辑
          contactsCircleApi.getTitleName('', item.connectionsId).then(res => {
            if (res) {
              state.editCrtPage = true;
              Object.assign(state.activeItemCr, item);
            } else {
              state.editPage = true;
              Object.assign(state.activeItem, item);
            }
          });
        } else {
          state.editPage = true;
          Object.assign(state.activeItem, item);
        }
        console.log('state.activeItemCr 人脉圈编辑', state.activeItemCr)
      },

      handleDelete(item) {
        // console.log('handleDelete', item);
        Modal.confirm({
          title: `您确定要删除此待办吗？所有相关数据都将被删除，并且此操作无法撤消。`,
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okType: '',
          okText: '确认',
          onOk: () => {
            todayApi
              .delteMatter('resources:delete', { ids: [item.id] })
              .then(res => {
                if (res) {
                  message.success('操作成功');
                  refreshGrid([item.id].length);
                }
              });
          },
        });
      },
      handleTime(item) {
        state.showTime = !state.showTime;
        console.log('handleTime', item);
      },
      handleGou(item) {
        // console.log('handleGou', item);
        // 调取主待办完成接口
        todayApi
          .markTodoCompleted('', {
            userId: store.state.account.user.id,
            ...item,
            id: item.id,
            completed: true,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              refreshGrid([item.id].length);
            }
          });
      },
      handleEditPageCr(value) {
        state.editCrtPage = value;
        // 刷新列表
        initList(state.isCurrent, state.size);
      },

      handleTodoDetailsCr(value) {
        state.opneCrTodoDetails = value;
        // 刷新列表
        initList(1, state.size);
      },
      handleCheck(item, listItem, completeState) {
        // console.log('handleCheck', listItem, listItem.completed);
        // 调取子待办完成接口
        todayApi
          .markTodoSubItemsCompleted('', {
            userId: store.state.account.user.id,
            todoId: item.id,
            id: listItem.id,
            completed: completeState,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              // initList(state.isCurrent, state.size);
              todayApi
                .queryTodoSubItem('', {
                  id: listItem.id,
                })
                .then(res => {
                  listItem.subContent = res.subContent;
                  listItem.completed = res.completed;
                });
            }
          });
      },
      gotoAll() {},
      handleEditPage(value) {
        state.editPage = value;
        initList(state.isCurrent, state.size);
      },
      handleTodoDetails(value) {
        state.opneTodoDetails = value;
        // 刷新列表
        initList(1, state.size);
      },
      handleReminderTime(item) {
        if (item?.reminderTime) {
          // 创建一个日期对象
          let dateObject = new Date(item?.reminderTime);
          // 提取月份、日期、小时和分钟
          let month = dateObject.getMonth() + 1; // 月份从0开始，所以需要加1
          let day = dateObject.getDate();
          let hours = dateObject.getHours();
          let minutes = dateObject.getMinutes();
          // let reminderTime = "2024-01-24 15:37:29"
          // 格式化输出
          let formattedTime = `${month}月${day}日 ${
            hours >= 10 ? hours : '0' + hours
          }:${minutes >= 10 ? minutes : '0' + minutes}`;
          return formattedTime;
        } else {
          return '';
        }
      },
      handleChangeByDate: (dates, dateStrings) => {
        state.searchDate = dateStrings[0]
          ? {
              startTime: `${dateStrings[0]} 00:00:00`,
              endTime: `${dateStrings[1]} 23:59:59`,
            }
          : {};
        initList(1, state.size);
      },
      handleSearch: value => {
        state.content = value.search;
        initList(1, state.size);
      },
      handleSearchTask: value => {
        state.searchType = value;
        initList(1, state.size);
      },
      handleSearchTimeTask: value => {
        state.timeType = value;
        initList(1, state.size);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  //max-height: calc(100vh - 110px);
  background: #ffffff;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  border-radius: 0 8px 8px 0;

  .list-title {
    padding: 14px 20px;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0 8px 0 0;
  }
  .move {
    cursor: pointer;
  }
  .banner {
    height: 80px;
    width: 142px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .title {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  .address {
    margin-top: 4px;
    min-height: 22px;
    img {
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-right: 4px;
    }
  }
  // :deep(.ant-spin-nested-loading) {
  //   max-height: ;
  // }
  :deep(.ant-list-pagination) {
    margin: 20px 20px 20px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
  :deep(.ant-checkbox-group) {
    border-radius: 0 0 8px 0;
  }
}

.statistic-item {
  width: 110px;
  flex-shrink: 0;
  .item-title {
    // margin-top: 5px;
    margin-right: 8px;
    color: @text-color-secondary;
  }
  .item-count {
    text-align: right;
    font-size: 24px;
    color: @primary-color;
  }
}
// :deep(.ant-list-items) {
//   min-height: calc(100vh - 273px);
//   height: calc(100vh - 309px);
//   overflow-y: auto;
// }
.userName {
  padding-left: 32px;
}
.list-time {
  padding-top: 4px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  min-width: 24px;
}
.list-wrapper /deep/ .ant-list-item-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-wrapper /deep/.ant-list-item-meta-title {
  width: 45%;
}
.list-wrapper /deep/ .ant-list-item-meta-description {
  width: 55%;
}

.listName {
  //width: 500px;
  width: 90%;
  //width: 350px;
  margin-right: 24px;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
  cursor: pointer;
}
.pink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //width: 340px;
  //width: 400px;
  width: 70%;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 500px;
  //width: 671px;
  width: 100%;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/

  .avatarClass {
    //width: 400px;
    //width: 294px;
    margin-right: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    //width: auto;
    min-width: 6%;
    .headerText {
      min-width: 26px;
    }
    .textMl {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .textMlC {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    align-items: baseline;
    cursor: pointer;
    width: 30%;
    .statisticMun {
      margin-right: 12px;
      .item-title {
        .fenbuImg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
    .ribbon {
      width: 96px;
      margin-right: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .dropDown {
        position: relative;
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .editImg {
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
        .editDelete {
          background: #fff;
          padding: 8px 16px;
          border-radius: 4px;
          position: absolute;
          top: 26px;
          left: -18px;
          cursor: pointer;
          width: 104px;
          color: #3d3d3d;
          z-index: 2;
          .edit {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .editImg {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
          .delete {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 16px;
            .deleteImg {
              width: 16px;
              height: 16px;
              color: #ff8f1f;
              margin-right: 10px;
            }
          }
        }
      }

      .timeTime {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        margin: 0px 8px;
        .deleteImg {
          //margin: 0px 12px;
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
      }

      .gouGou {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .gou {
          margin-left: 4px;
        }
      }
      .timeModal {
        position: absolute;
        top: 30px;
        left: -130px;
      }
    }
  }
}
.showBac {
  background: #fffbf6;
  border-left: 2px solid #ff7b00;

  /deep/ .anticon-caret-down {
    transform: rotate(180deg);
    // transition: transform 0.3s;
  }
}
.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 13px 0px;
  .showBor {
    border-bottom: 1px solid #eeeeee;
  }

  .listItemName {
    width: 524px;
    margin-left: 10px;
  }
  .showLine {
    text-decoration: line-through;
    color: rgba(102, 102, 102, 0.9);
  }
}
.searchClass {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0px 0px;
}
.iconClass {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffc85b;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
  min-width: 26px;
}
.flexCalss {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerTextC {
  background: #ffc85b;
  margin-right: 2px;
}
.lateBac {
  background: #fff1f1;
}

/deep/ .ant-list-item-meta-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/deep/ .ant-list-item {
  padding-left: 10px;
}

.list-dot-icon {
  width: 14px;
  height: 14px;
  margin-top: 2px;
}
</style>
